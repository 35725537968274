:root {
  --background-color: white;
  --sidebar-background-color: #f7f7f7;
  --body-color: #121212;
  --border-color: #e3e3e3;
  --button-color: #3992ff;
  --input-background-color: white;
  --input-border-color: hsla(0, 0%, 0%, 0.2);
  --input-border-hover-color: hsla(0, 0%, 0%, 0.6);
  --button-danger-color: #f44250;
  --button-active-shadow-color: #hsla(0, 0%, 0%, 0.4);
  --button-disabled-color: #818181;
  --i-color: #818181;
}

/* dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #121212;
    --sidebar-background-color: #222;
    --body-color: #f7f7f7;
    --border-color: #333333;
    --button-color: #6baeff;
    --input-background-color: #1d1d1d;
    --input-border-color: hsla(0, 0%, 100%, 0.2);
    --input-border-hover-color: hsla(0, 0%, 100%, 0.6);
    --button-danger-color: #f44250;
    --button-active-shadow-color: hsla(0, 0%, 100%, 0.4);
    --button-disabled-color: #bbbbbb;
    --i-color: #bbbbbb;
  }
}


html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.json {
  font-size: 1.1em;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: var(--body-color);
  background-color: var(--background-color);
}
textarea,
input,
button {
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0px 1px var(--input-border-color), 0 1px 2px var(--input-border-color);
  background-color: var(--input-background-color);
  line-height: 1.5;
  margin: 0;
  color: inherit;
}
button {
  color: var(--button-color);
  font-weight: 500;
}

textarea:hover,
input:hover,
button:hover {
  box-shadow: 0 0px 1px var(--input-border-hover-color), 0 1px 2px var(--input-border-color);
}

button:active {
  box-shadow: 0 0px 1px var(--button-active-shadow-color);
  transform: translateY(1px);
}


#search-form label {
  display: flex;
  align-items: center;
}

#search-form label p {
  flex-basis: 4rem;
}

#search-form label input {
  flex-grow: 1;
}

#search-form label textarea {
  flex-grow: 2;
  min-height: 10rem;
  resize: vertical;
}

#search-form label button:first-of-type {
  margin-left: 4rem;
}

.danger button {
  color: var(--button-danger-color);
}

form button:disabled {
  color: var(--button-disabled-color);
}

form button:disabled:hover {
  color: var(--button-disabled-color);
  box-shadow: 0 0px 1px var(--input-border-color), 0 1px 2px var(--input-border-color);
}

.boxed {
  border: solid 1px var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
}

.item-link {
  color: inherit;
  text-decoration: none;
}

.item {
  border: solid 1px var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
}

.item h3 {
  margin-block-start: 0.5rem;
}

.item:hover {
  background: var(--border-color)
}

.overflow {
  overflow: auto;
}

#login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
}

#sidebar {
  flex: 1;
  border-right: solid 1px var(--border-color);
  display: flex;
  flex-direction: column;
  background-color: var(--sidebar-background-color);
}

#sidebar > * {
  padding-left: 2rem;
  padding-right: 2rem;
}

#sidebar h1 {
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 1rem 2rem;
  border-top: 1px solid var(--border-color);
  order: 1;
  line-height: 1;
}

#sidebar nav {
  flex: 1;
  overflow: auto;
  padding-top: 1rem;
}

i {
  color: var(--i-color);
}

#sidebar ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#sidebar li {
  margin: 0.25rem 0;
}

#sidebar nav a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  white-space: pre;
  padding: 0.5rem;
  border-radius: 8px;
  color: inherit;
  text-decoration: none;
  gap: 1rem;
}

#sidebar nav a:hover {
  background: var(--border-color)
}

#detail {
  flex: 4;
  padding: 2rem 4rem;
  width: 100%;
}

@media (max-width: 600px) {
  #detail {
    padding: 1rem;
  }
}

#item-form {
  display: flex;
  /* max-width: 40rem; */
  flex-direction: column;
  gap: 1rem;
}


#item-form label {
  display: flex;
}

#item-form label input,
#item-form label textarea {
  flex-grow: 2;
  min-height: 20rem;
  resize: vertical;
}

#item-form label:last-child {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

#item-form label:last-child button {
  flex-basis: 6rem;
}

#item-form label:last-child button[type="button"] {
  color: inherit;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#error-page a {
  color: var(--button-color);
  text-decoration: none;
}

/* phone */
@media (max-width: 600px) {
  #root {
    flex-direction: column;
  }
  #sidebar {
    width: 100%;
    border-right: none;
    border-bottom: solid 1px var(--border-color);
  }
}

